import { HashLink } from 'react-router-hash-link';
import React from 'react' ;
import { useLocation } from "react-router-dom";

const OfferButton = ({ color, title, image }) => {
  const elementId = title.toLowerCase().trim().replace(/ /g, '-');
  const to = `/oferta/#${elementId}`;

  // let location = useLocation();
  
  // React.useEffect(() => {
  //   if (location.hash) {
      
  //     const el = document.querySelector(`#${elementId}`);

  //     if (!el) {
  //       return;
  //     }

  //     // $event.preventDefault();
  //     // $event.stopPropagation();

  //     setTimeout(() => {
  //       el.scrollIntoView({
  //         behavior: 'smooth'
  //       });
  //     }, 0);
  //   }
  // }, [location, elementId]);

  return (
    <div className="nav-link px-2 px-md-5 link-secondary">
      <HashLink to={to} className={`offer-button ${color}`}>
        <h3 className={`fs-2 offer-title text-${color === 'secondary' ? 'primary' : 'secondary'}`}>
          {title}
        </h3>
        <span className="background"></span>
        <img className="offer-image" src={`/images/${image}`} alt={title}/>
        <img src="/images/ni/chevron-right.svg" alt="" className="chevron-right"/>
        <img src="/images/ni/chevron-right.svg" alt="" className="chevron-down"/>
      </HashLink>
    </div>
  )
}

export default OfferButton
