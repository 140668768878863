import { Container, Button } from 'react-bootstrap'
import InstagramButtons from '../components/InstagramButtons'
import Meta from '../components/Meta'
import { HashLink } from 'react-router-hash-link';

const About = (props) => {
  // page content
  const pageTitle = props.data.about_head;

  return (
    <Container fluid className="position-relative p-0">
      <img alt="" src="/images/ni/shape-4.svg" className="shape-1" />
      <Meta title={pageTitle} description={props.data.about_description} og={{
        title: props.data.about_og_title,
        url: props.data.about_og_url,
        description: props.data.about_og_description,
        image: props.data.about_og_image
      }}/>
      <Container>
        <div className="row mt-5 mb-0 mb-md-5 pt-5 pb-0 pb-md-5">
          <div className="col-12 col-md-6 order-md-2 ps-md-0">
            <h1 className="text-primary mb-5 fs-1">
              {props.data.about_head}
            </h1>
            <p className='mt-4 mb-4' style={{whiteSpace: 'pre-line'}}>
              {props.data.about_instagram_1.split(`
`).join(`\n`)}
            </p>
            <InstagramButtons data={props.data} color={'primary'} onlyFirst withoutShadow></InstagramButtons>
            <p className='mt-4 pt-5 mb-4' style={{whiteSpace: 'pre-line'}}>
              {props.data.about_instagram_2.split(`
`).join(`\n`)}
            </p>
            <InstagramButtons data={props.data} color={'primary'} onlySecond withoutShadow></InstagramButtons>
          </div> 
          <div className="col-12 col-md-6 mt-5 mt-md-0 text-center order-md-1 pe-md-5">
          <img alt="MediaTwins - o nas" src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-o-nas.jpg" className="about-image" /> 
          </div> 
        </div> 
      </Container>
      <Container fluid className='bg-quaternary py-4'>
        <img alt="" src="/images/ni/shape-2.svg" className="shape-2" /> 
        <Container>
          <div className="row mt-5 py-5">
            <div className="col-12 col-md-8 mx-auto text-center position-relative">
              <img alt="" src="/images/ni/quote.svg" className="quote-image" /> 
              <h2 className="text-primary fs-1" style={{whiteSpace: 'pre-line'}}>
                {props.data.about_cta_text.split(`
`).join(`\n`)}
              </h2>
              <HashLink to="/kontakt/">
                <Button size="lg" className="square-border-sw text-secondary fs-3 my-5 px-4 ps-5" variant="primary" type="submit">
                  {props.data.about_cta_button}
                </Button>
              </HashLink>
            </div> 
          </div> 
        </Container>
      </Container>
    </Container>
  )
}

export default About