import React, { useState } from 'react'
import Meta from '../components/Meta'
import { Container } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Loader from 'react-spinner-loader';

function lines(text) {
  return text.split(`
`).map(line => {
  const splittedElement = line.split(';');
  return {
    image: splittedElement[0],
    title: splittedElement[1] ? splittedElement[1] : '',
    url: splittedElement[2] ? splittedElement[2] : '',
  };
});
} 

const Realizations = ({data}) => {
  // page content
  const pageTitle = data.realizations_head;
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hidding, setHidding] = useState(false);

  const playIcon = React.createElement("div", null, <div className="poster">
    <img 
      src={data.realizations_desktop_video_poster_url}  
      onLoad={() => {
        setHidding(true);
        setTimeout(() => {
          setLoading(false);
        }, 300)
      }}  
      alt="Start"/>
  </div>);

  return (
    <Container fluid className="position-relative p-0">
      <img alt="" src="/images/ni/shape-2.svg" className="shape-1 d-none d-md-block rotate-180" />
      <img alt="" src="/images/ni/shape-4.svg" className="shape-2 d-none d-md-block" />
      <div
        className={`fading-loader ${hidding ? 'fading' : ''}`}
      >
        <Loader 
          type="body"
          show={loading}
        />
      </div>
      <Meta title={pageTitle} description={data.realizations_description} og={{
        title: data.realizations_og_title,
        url: data.realizations_og_url,
        description: data.realizations_og_description,
        image: data.realizations_og_image
      }}/>
      <ReactPlayer 
        className="realizations-video" 
        url={data.realizations_desktop_video_url} 
        light
        // volume={0.5}
        // muted
        controls
        fallback={playIcon}
        playIcon={playIcon}
        width="100%"
        height="auto"
        loop
        playing={playing}
        onClickPreview={() => setPlaying(true)}
        config={{
          file: {
            attributes: {
              style: {
                objectFit: 'cover',
                width: '100%',
                aspectRatio: '16 / 9',
                display: 'block',
              }
            }
          }
        }}
      />
      <Container className="pb-5 pt-5">
        <h2 className='text-center text-primary mt-5 mb-4'>
          {data.realizations_head}
        </h2>
        <Slider 
          className="mb-3"
          dots={false}
          infinite
          speed={500}
          autoplay
          lazyLoad="ondemand"
          slidesToShow={5}
          slidesToScroll={3}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1
              }
            }
          ]}
        >
        {lines(data.realizations_list).map((element, i) => (
          <div className="realization-slide" key={i}>
          {element.url && (
            <a href={element.url} target="_blank" rel="noreferrer">
              <img className="realization-image" alt={element.title} src={element.image} />        
            </a>
          )}
          {!element.url && (
            <img className="realization-image" alt={element.title} src={element.image} />        
          )}
          </div>
        ))}
        {lines(data.realizations_list).map((element, i) => (
          <div className="realization-slide" key={i}>
          {element.url && (
            <a href={element.url} target="_blank" rel="noreferrer">
              <img className="realization-image" alt={element.title} src={element.image} />        
            </a>
          )}
          {!element.url && (
            <img className="realization-image" alt={element.title} src={element.image} />        
          )}
          </div>
        ))}
        </Slider>
      </Container>
    </Container>
  )
}

export default Realizations