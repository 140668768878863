import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Meta from '../components/Meta'

const NotFound = ({data}) => {
  return (
    <>
    <Meta title={'404'}/>
    <Container className="mt-5 position-relative">
      <img alt="" src="/images/ni/shape-2.svg" className="shape-1" />
      <img alt="" src="/images/ni/shape-1.svg" className="shape-2" /> 
      <Row>
        <Col>
          <div className="mx-auto pb-5">
            <div className="text-center">
              <h5 className="text-primary fs-1 mt-3">Ups! Error 404.</h5>
              <p className="serif my-5 fs-4">
                Strona, której szukasz nie istnieje lub została usunięta <br/>
                Nie martw się. Po prostu...
              </p>
              <Link to="/">
                <button className="btn btn-primary text-secondary btn-lg square-border-sw mb-5">...wróć na stronę główną!</button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default NotFound;
