import React, { useState } from 'react' 
import { Link } from "react-router-dom";
import { Nav, Container, Navbar } from "react-bootstrap";
import InstagramButtons from "./InstagramButtons";
import { useLocation } from "react-router-dom";

const Menu = ({data}) => {
  const [pathName, setPathName] = useState(window.location.pathname);
  const [expanded, setExpanded] = useState(false);

  const goTo = (path) => {
    setPathName(path);
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 0)
  }

  let location = useLocation();
  React.useEffect(() => {
    setPathName(location.pathname);
    if (location.hash) {
      setExpanded(false);
      
      const el = document.querySelector(decodeURIComponent(location.hash));
      if (!el) {
        return;
      }
      
      el.scrollIntoView({
        behavior: 'smooth'
      });
      return;
    }

    window.scrollTo(0,0);
  }, [location]);

  return (
    <Navbar sticky="top" expand="lg" className="bg-primary" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <div>
              <Link
                onClick={() => goTo("/")}
                to="/"
                className="d-inline-block mb-2 mb-lg-0 text-decoration-none me-1 me-sm-2 text-secondary"
              >
                <img height="70px" className="logo" alt="MediaTwins" src="/images/logo.svg" />  
              </Link>
              {/* <a type="button" href={`tel:+48${data.tel.replace(/ /g, '')}`} className="nav-tel-btn d-inline-block text-decoration-none btn btn-secondary text-primary square-border-se me-1 me-sm-2 fw-bolder">
                <i className="icon-phone"></i>
                {data.tel}
              </a> */}
              <a type="button" href="https://videobook.mediatwins.pl" className="nav-tel-btn d-inline-block text-decoration-none btn btn-secondary text-primary square-border-se me-1 me-sm-2 fw-bolder">
                VIDEOBOOK
              </a>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" className="text-secondary btn-lg" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto pb-3 pb-lg-0">
              <ul className="nav col-12 col-lg-auto mb-2 flex-column text-center flex-lg-row align-content-center justify-content-center mb-lg-0 fw-bold">
                <li style={{display: data.about_show_in_menu ? 'flex' : 'none'}}>
                  <Link onClick={() => goTo("/o-nas/")} href="/o-nas/" to="/o-nas/" className={`${pathName === '/o-nas' ? 'active' : ''} nav-link px-2 link-secondary text-secondary`}>
                    O nas
                  </Link>
                </li>
                <li style={{display: data.offer_show_in_menu ? 'flex' : 'none'}}>
                  <Link onClick={() => goTo("/oferta/")} href="/oferta/" to="/oferta/" className={`${pathName === '/oferta' ? 'active' : ''} nav-link px-2 link-secondary text-secondary`}>
                    Oferta
                  </Link>
                </li>
                <li style={{display: data.realizations_show_in_menu ? 'flex' : 'none'}}>
                  <Link onClick={() => goTo("/realizacje/")} href="/realizacje/" to="/realizacje/" className={`${pathName === '/realizacje' ? 'active' : ''} nav-link px-2 link-secondary text-secondary`}>
                    Realizacje
                  </Link>
                </li>
                <li style={{display: data.contact_form_show_in_menu ? 'flex' : 'none'}}>
                  <Link onClick={() => goTo("/kontakt/")} href="/kontakt/" to="/kontakt/" className={`${pathName === '/kontakt' ? 'active' : ''} nav-link px-2 link-secondary text-secondary`}>
                    Kontakt
                  </Link>
                </li>
              </ul>

              <div className="col-lg-auto mx-auto text-end ms-lg-2 ms-lg-4 me-lg-0 nav-instagram-links">
                {/* <InstagramButtons data={data} withoutShadow color={'secondary'}></InstagramButtons> */}
                <InstagramButtons data={{...data, instagram_1: '@mediatwinspl', instagram_link_1: 'https://www.instagram.com/mediatwinspl/?hl=pl'}} onlyFirst withoutShadow color={'secondary'}></InstagramButtons>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
    </Navbar>
  );
};

export default Menu;
