const data = {
    "tel": "662 018 561",
    "email": "kontakt@mediatwins.pl",
    "instagram_1": "@shinysyl",
    "instagram_2": "@lelcia",
    "instagram_link_1": "https://www.instagram.com/shinysyl/?hl=pl",
    "instagram_link_2": "https://www.instagram.com/lelcia/?hl=pl",
    "home_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "home_og_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "home_og_title": "MediaTwins",
    "home_og_image": "https://mediatwins.pl/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-1.jpg",
    "home_og_url": "https://mediatwins.pl",
    "home_lead": "Nauczymy Cię Instagrama!",
    "home_bg_mobile": "/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-7.jpg",
    "home_bg_desktop": "/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-1.jpg",
    "home_s1_head": "Kim jesteśmy?",
    "home_s1_content": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. \r\n\r\nW Media Twins nauczymy Cię od podstaw jak prowadzić profil na Instagramie, jak tworzyć materiały (fotografować, nagrywać, montować za pomocą darmowych aplikacji). Zajmujemy się zarówno budowaniem profili od zera jak i audytami istniejących kont. Chcesz aby profesjonalista spojrzał z boku na Twoje działania? Podrzucił pomysły na treści? Pomógł rozpromować biznes i zyskać nowych obserwatorów oraz klientów? Właśnie od tego jesteśmy!\r\n\r\nCo jest dla nas najważniejsze? Indywidualność - na to stawiamy w Media Twins!",
    "home_s2_head": "Co możemy dla Ciebie zrobić?",
    "contact_form_show_in_menu": "1",
    "contact_form_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "contact_form_og_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "contact_form_og_title": "MediaTwins | Kontakt",
    "contact_form_og_image": "",
    "contact_form_og_url": "https://mediatwins.pl/kontakt",
    "contact_form_photo": "images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-kontakt.jpg",
    "contact_form_head": "Chcesz z nami działać?",
    "contact_form_content": "Napisz czego oczekujesz\r\na my odezwiemy się w ciągu 24 godzin",
    "about_show_in_menu": "1",
    "about_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "about_og_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "about_og_title": "MediaTwins | O nas",
    "about_og_image": "",
    "about_og_url": "https://mediatwins.pl/o-nas",
    "about_head": "O nas",
    "about_instagram_1": "Sylwia - działam w sieci od 2011 roku. Jestem specjalistką od materiałów fotograficznych i wideo. Realizowałam kampanie dla takich marek jak Mercedes, Pinko, Apart, Pandora, Ikea, Jysk, Estee Lauder, sieć hoteli Accor czy Allegro. Mam ogromne doświadczenie w planowaniu strategii, wymyślaniu kontentu i jego realizacji właściwie dla każdej branży. Na pewno pomogę też Tobie!",
    "about_instagram_2": "Ewelina - od 2015 roku rozwijam swoją markę osobistą. Dzięki działalności na Instagramie sprzedaję autorskie e-booki treningowe oraz jadłospisy. Dodatkowo realizuję kampanie reklamowe dla marek takich jak Nike, 4F, iDream, Garmin, Oral-B, Philips, Piątnica czy Lidl. Wymyślam kampanie marketingowe, zajmuję się ich realizacją, a także tworzeniem zdjęć, grafik i materiałów video. \r\n\r\nŁącznie obserwuje nas prawie 150 tysięcy osób!",
    "about_cta_text": "\"Jeśli chcesz się rozwinąć, musisz zrobić coś innego niż większość ludzi\"\r\nLakshmi Mittal",
    "about_cta_button": "Skontaktuj się z nami",
    "offer_show_in_menu": "1",
    "offer_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "offer_og_description": "Ewelina i Sylwia, działamy od 10 lat w social marketingu. Jesteśmy w tym świecie praktycznie od początku jego powstawania w Polsce. Wiemy, jak działa branża od środka – same prowadzimy prężnie działające profile. ",
    "offer_og_title": "MediaTwins | Oferta",
    "offer_og_image": "",
    "offer_og_url": "https://mediatwins.pl/oferta",
    "offer_1_title": null,
    "offer_2_title": null,
    "offer_3_title": null,
    "offer_1_photo": "media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-audyt.jpg",
    "offer_2_photo": "media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-pelne-szkolenie.jpg",
    "offer_3_photo": "media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-tworzenie-kontentu.jpg",
    "offer_1_tag_1": "stacjonarnie Trójmiasto",
    "offer_1_tag_2": "on-line",
    "offer_1_price": "1000",
    "offer_1_button": "Zamawiam Audyt",
    "offer_1_list_1": "Masz poczucie, że Twój profil na Instagramie stanął w miejscu? Damy Ci wiedzę jak to zmienić.\r\nJakiego rodzaju materiały wrzucać - posty, rolki czy stories? Dostaniesz listę inspiracji i pomysłów dopasowanych do Twojego profilu.\r\nCo musi znaleźć się na Twoim profilu, aby osoba która na niego wejdzie już została? Zdradzimy Ci triki na tworzenie angażujących treści.\r\nCzy współpracować z influencerami i jeśli tak, to na jakich zasadach? \r\nCzy współpracować z markami, jak takie działania wyceniać, jak negocjować i czego się wystrzegać?\r\nJakie są metody zwiększenia widoczności profilu? Oznaczanie, lokalizacje, hashtagi - otrzymasz przykłady jak to przygotować.\r\nCo zamieszczać w relacjach wyróżnionych i dlaczego są tak bardzo istotne? Dostaniesz szczegółową listę jakie powinieneś przygotować wraz ze wskazówkami jak to zrobić.\r\nCzy stories powinno pojawiać się codziennie i co tam zamieszczać? Otrzymasz listę pomysłów przygotowanych pod Twoją działalność.\r\nJak stworzyć od podstaw wizytówkę „o mnie”, która jest niezbędna do przedstawienia Twojej działalności nowym użytkownikom, a także do promowania profilu? Powiemy Ci w jakiej aplikacji ją stworzyć, jak dodać animacje, muzykę i oryginalne elementy.",
    "offer_1_list_1_style": "question",
    "offer_1_head": "Jak to wygląda w praktyce?",
    "offer_1_list_2": "Konsultacja odbywa się w formie spotkania face to face na terenie Trójmiasta lub online.\r\nCzas trwania to około dwie godziny.\r\nAnalizę i rozwiązania przygotowujemy wcześniej, tak aby czas spotkania był efektywnie wykorzystany. Przedstawimy Ci konkrety, bo dbamy o Twój czas! :)\r\nPo spotkaniu dostajesz podsumowanie najważniejszych założeń. \r\nKażda osoba i każda działalność jest inna, pomożemy Ci dopasować tworzone treści stricte pod Ciebie, uzyskasz gotowe rozwiązania jakie informacje i w jakiej formie powinny się pojawić. ",
    "offer_1_list_2_style": "tilde",
    "offer_1_details": "",
    "offer_2_tag_1": "stacjonarnie Trójmiasto",
    "offer_2_tag_2": "cała Polska*",
    "offer_2_price": "4000",
    "offer_2_button": "Zamawiam Pełne szkolenie",
    "offer_2_list_1": "Jakie treści warto zamieszczać? Otrzymasz gotowe pomysły i przykłady materiałów (zdjęcia, reels, stories) dopasowanych do Twojej działalności wraz ze szczegółową instrukcją jak je stworzyć.\r\nJakich darmowych aplikacji używać? Pokażemy Ci na żywo cały proces fotografowania i nagrywania wraz ze wszystkimi trikami, dynamicznymi przejściami, które można zastosować - razem stworzymy treści na Twój profil (dostaniesz gotowy kontent). \r\nJaką przyjąć strategię prowadzenia profilu? Nagrywać rolki, czy skupiać się na zdjęciach? Wspólnie wszystko zaplanujemy!\r\nJak zacząć współpracę z influencerami? Jak sprawdzić czy warto z daną osobą podjąć działania? Na jakich zasadach przeprowadzać współprace? Czego wymagać i jakich efektów się spodziewać?\r\nJak współpracować z markami? Jak rozpocząć? Czy wypada samemu zgłaszać się do firm i jeśli tak, to w jakiej formie? Jak wyceniać działania? Czego może od Ciebie wymagać marka? W jakiej formie się rozliczać?",
    "offer_2_list_1_style": "question",
    "offer_2_head": "Jak to wygląda w praktyce?",
    "offer_2_list_2": "Spotykamy się na żywo w Twoim miejscu pracy, na około 6-8 godzin. \r\nZaczynamy od rozmowy, czyli od przedstawienia wyników audytu i następnie przechodzimy do działania, czyli do aktywnego szkolenia, w trakcie którego razem tworzymy materiały i pokazujemy Ci wszystko w praktyce. \r\nWspólnie przygotowujemy posty, reels, wyróżnione relacje oraz opis Twojego profilu - będziesz mieć gotowe materiały do wykorzystania.\r\nRealizując szkolenie zdobędziesz umiejętności, które pozwolą Ci przygotować wyjątkowe treści. Dzięki niemu będziesz tworzyć kontent w trakcie codziennej działalności Twojej firmy. Zdobędziesz wiedzę, którą wykorzystasz w praktyce.\r\nPo spotkaniu otrzymasz podsumowanie najważniejszych założeń oraz linki do przygotowanych przez nas specjalnych tutoriali do aplikacji z wytłumaczeniem najważniejszych opcji.",
    "offer_2_list_2_style": "tilde",
    "offer_2_details": "",
    "offer_3_tag_1": "stacjonarnie Trójmiasto",
    "offer_3_tag_2": "cała Polska",
    "offer_3_price": "1000",
    "offer_3_button": "Zamawiam Pakiet start",
    "offer_3_list_1": "",
    "offer_3_list_1_style": "question",
    "offer_3_head": "",
    "offer_3_list_2": "Nazwa, opis w bio, hashtagi - przygotujemy to dla Ciebie. Bio ma 150 znaków. Wiemy jak wykorzystać każdy na 100%. \r\nWyróżnione relacje - dostaniesz szczegółową listę jakie powinieneś przygotować wraz ze wskazówkami jak to zrobić.\r\nSpójny wygląd profilu - pomożemy Ci to zaplanować i pokażemy jak wprowadzić identyfikacje marki w przypadku każdej opcji postowania. \r\nPosty, rolki, stories? Dostaniesz listę inspiracji i pomysłów dopasowanych do Twojego profilu. \r\nPodpowiemy Ci czy współpracować z influencerami i jeśli tak, to na jakich zasadach? \r\nProgramy do obróbki zdjęć, edycji wideo, robienia grafik - zdradzimy Ci których używać i w jaki sposób. ",
    "offer_3_list_2_style": "tilde",
    "offer_3_details": "",
    "realizations_desktop_video_url": "https://css-tricks-post-videos.s3.us-east-1.amazonaws.com/blurry-trees.mov",
    "realizations_desktop_video_poster_url": "/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-realizacje.png",
    "realizations_mobile_video_url": null,
    "realizations_mobile_video_poster_url": null,
    "realizations_list": "/images/ni/down-main.svg\r\n/images/ni/chevron-right.svg\r\n/images/ni/down-main.svg\r\n/images/ni/chevron-right.svg",
    "offer_1_list_1_above": "Kompleksowa analiza Twojego profilu na Instagramie.\r\nOferta skierowana jest zarówno do firm, jak i osób chcących zbudować profil swojej marki osobistej.",
    "offer_1_list_1_below": "",
    "offer_1_list_2_above": "",
    "offer_1_list_2_below": "Do podanej poniżej ceny należy doliczyć 23% VAT.",
    "offer_2_list_1_above": "*w przypadku szkolenia wyjazdowego prosimy o kontakt w celu ustalenia kosztów dojazdu i ewentualnego noclegu\r\n\r\nOferta obejmuje wszystkie pozycje z opcji AUDYT oraz kompleksowe, indywidualne szkolenie z zakresu tworzenia treści na Instagram. Z tej opcji skorzystać mogą zarówno firmy, jak i osoby chcące zbudować profil swojej marki osobistej.\r\n\r\nCzytasz dziesiątki e-booków odnośnie Instagrama, a nadal nie wiesz jakie materiały tworzyć i jak dopasować je do swojej działaności?\r\nObserwujesz różne profile i też chcesz tworzyć coś podobnego, ale nie wiesz jak?\r\nChcesz się przełamać się i zacząć tworzyć materiały video?\r\n\r\nZastanawiasz się…",
    "offer_2_list_1_below": "",
    "offer_2_list_2_above": "",
    "offer_2_list_2_below": "W trakcie zamawiania usługi zachęcamy Cię, aby w wiadomości do nas wskazać obszary z jakimi masz największy problem. Pamiętaj, że podczas rozmów z nami nie ma głupich czy dziwnych pytań, masz 1000% naszej uwagi, wiedzę i szczere odpowiedzi.  \r\n\r\nDo podanej poniżej ceny należy doliczyć 23% VAT.",
    "offer_3_list_1_above": "Dedykowany osobom, które dopiero zakładają profil swojej marki/usługi na Instagramie. \r\nZ nami zaczniesz z przytupem! :) Początki prowadzenia biznesu to nie czas na błądzenie po omacku. Szkoda na to czasu... I tu pojawiamy się my. Pomożemy wszystko przygotować i zacząć z wysokiego A! Sprawimy, że ten nowo stworzony profil na Instagramie będzie doskonałą wizytówką Twojej firmy i co dla każdej branży ważne... wykorzystasz sprzedażowy potencjał tej platfromy.",
    "offer_3_list_1_below": "",
    "offer_3_list_2_above": "",
    "offer_3_list_2_below": "Do podanej poniżej ceny należy doliczyć 23% VAT.",
    "offer_1_title_line_1": "Audyt",
    "offer_1_title_line_2": "",
    "offer_2_title_line_1": "Pełne",
    "offer_2_title_line_2": "Szkolenie",
    "offer_3_title_line_1": "Pakiet",
    "offer_3_title_line_2": "Start",
    "realizations_show_in_menu": null,
    "realizations_description": "",
    "realizations_og_description": "",
    "realizations_og_title": "",
    "realizations_og_image": "",
    "realizations_og_url": "",
    "realizations_head": "Nasze realizacje"
};
export default data;