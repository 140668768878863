import { useState } from 'react'
import Meta from '../components/Meta'
import { Parallax, Background } from 'react-parallax'
import { Container } from "react-bootstrap";
import Loader from 'react-spinner-loader';
import InstagramButtons from '../components/InstagramButtons';
import OfferButton from '../components/OfferButton';
import ContactForm from '../components/ContactForm';

const Home = (props) => {
  const [loading, setLoading] = useState(true);
  const [hidding, setHidding] = useState(false);

  // page content
  const pageTitle = ''

  return (
    <>
      <div
        className={`fading-loader ${hidding ? 'fading' : ''}`}
      >
        <Loader 
          type="body"
          show={loading}
        />
      </div>
      <Meta title={pageTitle} description={props.data.home_description} og={{
        title: props.data.home_og_title,
        url: props.data.home_og_url,
        description: props.data.home_og_description,
        image: props.data.home_og_image
      }}/>
      <Parallax strength={200}>
        <Background className="custom-bg">
          <img className="d-block d-md-none" src={props.data.home_bg_mobile} onLoad={() => {
            setHidding(true);
            setTimeout(() => {
              setLoading(false);
            }, 300)
          }} alt="MediaTwins" />
          <img className="d-none d-md-block" src={props.data.home_bg_desktop} onLoad={() => {
            setHidding(true);
            setTimeout(() => {
              setLoading(false);
            }, 300)
          }} alt="MediaTwins" />
        </Background>
        <Container className="text-center text-secondary">
          <div className='move-on-hover text-center my-5'>
            <h1> 
              <img alt="MediaTwins" src="/images/logo.svg" />       
            </h1>
            <h2 className='lead text-capitalize serif mx-auto'>
              {props.data.home_lead}
            </h2>
          </div>
          <img alt="" className="mt-5" src="/images/ni/down-main.svg" />    
        </Container>
      </Parallax>
      <Container className="intro position-relative">
      <img alt="" src="/images/ni/shape-1.svg" className="shape-1" /> 
      <img alt="" src="/images/ni/blub-1.svg" className="blub-1" /> 
        <div className="row mt-5 pt-5">
          <div className="col-12 col-md-6">
            <h2 className='mb-4 text-primary fs-1'>
              {props.data.home_s1_head}
            </h2>
          </div>
        </div>
        <div className="row mb-5 pb-5">
          <div className="col-12 col-md-6 order-md-3 move-on-hover text-center">
            <img alt="" className="phones-graphic" src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-8.svg" />   
          </div> 
          <div className="col-12 col-md-6 order-md-4 my-4 my-md-0 text-center text-md-start">
            <InstagramButtons data={props.data} color={'primary'} withoutShadow></InstagramButtons>
          </div> 
          <div className="col-12 col-md-6">
            <p className='mt-4 mb-5 text-muted fs-6 text-center text-md-start' style={{whiteSpace: 'pre-line'}}>
                {props.data.home_s1_content.split(`
`).join(`\n`)}
            </p>
          </div> 
        </div> 
      </Container>
      <Container fluid className='bg-quaternary position-relative pb-5'>
        <img alt="" src="/images/ni/shape-2.svg" className="shape-2" /> 
        <img alt="" src="/images/ni/shape-3.svg" className="shape-3" /> 
        <Container>
          <div className="row mt-5 py-5">
            <div className="col-6 col-md-12 text-start text-md-center">
              <h2 className='mb-0 mb-md-5 mt-md-5 text-primary fs-1'>
                {props.data.home_s2_head}
              </h2>
            </div> 
            <div className="col-12 col-md-6 col-lg-4 mb-5 mb-md-0 offer-button-container">
              <OfferButton color="primary" title={`${props.data.offer_1_title_line_1} ${props.data.offer_1_title_line_2}`} image={props.data.offer_1_photo}></OfferButton>
            </div> 
            <div className="col-12 col-md-6 col-lg-4 mb-5 mb-md-0 offer-button-container">
              <OfferButton color="primary" title={`${props.data.offer_2_title_line_1} ${props.data.offer_2_title_line_2}`} image={props.data.offer_2_photo}></OfferButton>
            </div> 
            <div className="col-12 col-md-6 mx-auto mt-0 mt-md-5 mt-lg-0 col-lg-4 mb-5 offer-button-container">
              <OfferButton color="primary" title={`${props.data.offer_3_title_line_1} ${props.data.offer_3_title_line_2}`} image={props.data.offer_3_photo}></OfferButton>
            </div> 
          </div>
        </Container>
      </Container>
      <Container fluid className='bg-tertiary pt-5 position-relative'>
        <img alt="" src="/images/ni/blub-2.svg" className="blub-2" /> 
        <ContactForm data={props.data} color="primary"></ContactForm>
      </Container>
    </>
  )
}

export default Home