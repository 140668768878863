// components
import Menu from "../components/Menu";
import Footer from "../components/Footer";

const Layout = ({ children, data }) => {
  return (
    <>
      <Menu data={data} />
      <main>{children}</main>
      <Footer data={data} />
    </>
  );
};

export default Layout;
