import { useState, useCallback, useEffect } from 'react'
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap"
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha
  } from 'react-google-recaptcha-v3';

const ContactForm = ({ color, data }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [sending, setSending] = useState(false);

    const handleReCaptchaVerify = useCallback(async (event) => {
        setError(false);

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (event && !executeRecaptcha) {
            setError(true);
        }

        if (!executeRecaptcha) {
            return;
        }
  
        const token = await executeRecaptcha(window.location.pathname);
        if (event && event.nativeEvent) {
            handleSubmit(event.nativeEvent, token);
        }
    }, [executeRecaptcha]);
  
    useEffect(() => {
      handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);
  
    const handleSubmit = async (event, reCaptchaToken) => {
      const form = event.currentTarget ? event.currentTarget : event.target;

      if (form.checkValidity() === false) {
        setValidated(true);
        return;
      }

      
      setValidated(true);
      setSuccess(false);
      setError(false);
      setSending(true);
      
      const response = await fetch(`/mail.php`, {
          method: 'POST',
          body: JSON.stringify({
            name: form.elements['name'].value,
            email: form.elements['email'].value,
            message: form.elements['message'].value,
            reCaptchaToken,
            website: window.location.pathname,
            nonce: Date.now() / 2
          })
      });
      const data = await response.text();
      setSending(false);
      if (response.status === 200 && data === 'OK') {
        form.reset();
        setValidated(false);
        setSuccess(true);
        return;
      }
      setError(true);
    };

    return (
        <>
            <Container className={`contact-form ${color} pt-5`}>
                <div className="row">
                    <div className="col-12 col-md-6 ps-0 order-md-2">
                        <h2 className="mb-4 text-primary fs-1">
                            {data.contact_form_head}
                        </h2>
                        <p style={{lineHeight: '28px', whiteSpace: 'pre-line'}} className={`mb-5 serif contact-paragraph fs-4 text-${color === 'primary' ? 'secondary' : 'dark' }`}>
                            {data.contact_form_content.split(`
`).join(`\n`)}
                        </p>
                        <div className="container p-0 pb-1 pb-md-5 mb-0 mb-md-5">
                            <Form noValidate validated={validated} onSubmit={handleReCaptchaVerify} action="/mail.php" method="POST">
                                <Form.Group className="mb-3 row" controlId="messageForm">
                                    <div className="col-12 col-md-5 pe-1">
                                        <Form.Control controlId="name" disabled={sending} size="lg" required className="text-primary" name="name" type="text" placeholder="Imię" />
                                        <Form.Control.Feedback type="invalid">
                                            Imię jest wymagane.
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-12 col-md-7 pe-1 pt-3 pt-md-0">
                                        <Form.Control 
                                            controlId="email"
                                            size="lg"
                                            disabled={sending}
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                            required className="text-primary" name="email" type="email" placeholder="adres e-mail" 
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Podaj prawidłowy email.
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-12 pt-3 pe-1">
                                        <Form.Control controlId="message"size="lg" disabled={sending} required className="text-primary" minLength="10" name="message" as="textarea" rows={3} placeholder="wiadomość" />
                                        <Form.Control.Feedback type="invalid">
                                            Wiadomość jest za krótka.
                                        </Form.Control.Feedback>
                                        <Form.Text className="text-secondary fs-6 non-serif">
                                            <small>
                                                Ta witryna jest chroniona przez reCAPTCHA i Google.
                                                Obowiązują <a className="text-secondary" href="https://policies.google.com/privacy">Polityka prywatności</a> oraz&nbsp; 
                                                <a className="text-secondary" href="https://policies.google.com/terms">Warunki korzystania z usługi</a>.
                                            </small>
                                        </Form.Text>
                                        
                                    </div>
                                </Form.Group>
                                <Button size="lg" disabled={sending} className="square-border-nw square-border-ne square-border-sw text-secondary px-4 pb-3 pt-2" variant="primary" type="submit">
                                    wyślij
                                    {sending && <Spinner className="ms-2" size="sm" animation="grow" variant="secondary" />}
                                </Button>
                                {error && <Alert variant="danger" className="non-serif mt-3">
                                    Wystąpił nieoczekiwany błąd. Spróbuj ponownie.
                                </Alert>}
                                {success && <Alert variant="success" className="non-serif mt-3">
                                    Wiadomość została wysłana.
                                </Alert>}
                            </Form>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 order-md-1 text-end text-md-center">
                        <img alt="MediaTwins - kontakt" src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-kontakt.jpg" className="contact-form-graphic" /> 
                    </div>
                </div>
            </Container>
        </>
    )
  }
  
  export default ContactForm
  