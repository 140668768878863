import { Container } from 'react-bootstrap'
import ContactForm from '../components/ContactForm'
import Meta from '../components/Meta'

const Contact = ({data}) => {
  // page content
  const pageTitle = 'Kontakt'

  return (
    <>
      <Meta title={pageTitle} description={data.contact_form_description} og={{
        title: data.contact_form_og_title,
        url: data.contact_form_og_url,
        description: data.contact_form_og_description,
        image: data.contact_form_og_image
      }}/>
      <Container fluid className="bg-quaternary px-3 p-md-0 position-relative">
        <img alt="" src="/images/ni/shape-1.svg" className="shape-1 d-none d-md-block" />
        <img alt="" src="/images/ni/shape-2.svg" className="shape-2 d-none d-md-block" /> 
        <img alt="" src="/images/ni/blub-1.svg" className="blub-1 blub-contact d-block d-md-none" /> 
        <ContactForm data={data} color="secondary"></ContactForm>
      </Container>
    </>
  )
}

export default Contact