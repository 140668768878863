import { Container } from "react-bootstrap";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react'
import defaultData from './data';

// Layout
import Layout from "./layout/Layout";

// pages
import Home from "./pages/Home";
import About from "./pages/About";
import Offer from "./pages/Offer";
import Realizations from "./pages/Realizations";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";

// GA
import ReactGA4 from "react-ga4";

function usePageViews() {
  let location = useLocation()

  useEffect(
    () => {
      ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    },
    [location]
  )
}

function App() {
  const [data, setData] = useState(defaultData);

  const getData = () => {
    return fetch('/cms/data.json')
      .then(res => res.json())
  }

  // const fetchData = () => {
  //   getData()
  //     .then(data => {
  //       console.log(data)
  //       setData(data);
  //     });
  // }

  useEffect(() => {
    getData()
      .then(data => {
        // console.log(data)
        setData({...defaultData, ...data});
      });
  }, [])

  usePageViews();

  return (<Layout data={data}>
    <Container fluid className="p-0 overflow-hidden">
      <Routes>
        <Route path="/" element={<Home data={data} />} exact />
        <Route path="/o-nas" element={<About data={data} />} exact />
        <Route path="/oferta" element={<Offer data={data} />} exact />
        <Route path="/realizacje" element={<Realizations data={data} />} exact />
        <Route path="/kontakt" element={<Contact data={data} />} exact />
        <Route path="/404" element={<NotFound data={data} />} />
        <Route path="*" element={<Navigate data={data} replace to="/404" />} />
      </Routes>
    </Container>
  </Layout>)
};
export default App;