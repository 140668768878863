const InstagramButtons = ({ color, onlyFirst, onlySecond, withoutShadow, data }) => {
  return (
      <>
        {!onlySecond && <a rel="noreferrer" href={data.instagram_link_1} className="d-inline-block" target="_blank">
          <button type="button" className={`btn btn-lg btn-${color} text-${color === 'secondary' ? 'primary' : 'secondary'} square-border-ne ${withoutShadow ? '' : 'shadow'} mt-2 me-2`}>
          <i className="icon-instagram"></i>
            {data.instagram_1}
          </button>
        </a>}
        {!onlyFirst && <a rel="noreferrer"  href={data.instagram_link_2} className="d-inline-block" target="_blank">
          <button type="button" className={`btn btn-lg btn-${color} text-${color === 'secondary' ? 'primary' : 'secondary'} square-border-se ${withoutShadow ? '' : 'shadow'} ${!onlySecond ? 'ms-2' : ''} me-2 mt-2`}>
          <i className="icon-instagram"></i>
            {data.instagram_2}
          </button>
        </a>}
      </>
  )
}

export default InstagramButtons
