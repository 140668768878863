import { Container, Button } from 'react-bootstrap';
import Meta from '../components/Meta';
import OfferButton from '../components/OfferButton';
import { HashLink } from 'react-router-hash-link';

function lines(text) {
  return text.split(`
`);
} 

const isNumber = text => !isNaN(Number(text.replace(/ /g, '').trim()));

const Offer = (props) => {
  // page content
  const pageTitle = 'Oferta';

  
  const offer1Id = `${props.data.offer_1_title_line_1} ${props.data.offer_1_title_line_2}`.toLowerCase().trim().replace(/ /g, '-');
  const offer2Id = `${props.data.offer_2_title_line_1} ${props.data.offer_2_title_line_2}`.toLowerCase().trim().replace(/ /g, '-');
  const offer3Id = `${props.data.offer_3_title_line_1} ${props.data.offer_3_title_line_2}`.toLowerCase().trim().replace(/ /g, '-');

  return (
    <div>
      <Meta title={pageTitle} description={props.data.offer_description} og={{
        title: props.data.offer_og_title,
        url: props.data.offer_og_url,
        description: props.data.offer_og_description,
        image: props.data.offer_og_image
      }}/>
      <Container fluid className='bg-tertiary position-relative'>
        <img alt="" src="/images/ni/shape-2.svg" className="shape-2 d-block d-lg-none" />
        <Container className="pt-5 pb-5 pb-md-0">
          <div className="row pt-5 pb-4">
            <div className="col-12 col-md-6 col-lg-4 mb-5 mb-md-0 offer-button-container">
              <OfferButton color="secondary" title={`${props.data.offer_1_title_line_1} ${props.data.offer_1_title_line_2}`} image={props.data.offer_1_photo}></OfferButton>
            </div> 
            <div className="col-12 col-md-6 col-lg-4 mb-5 mb-md-0 offer-button-container">
              <OfferButton color="secondary" title={`${props.data.offer_2_title_line_1} ${props.data.offer_2_title_line_2}`} image={props.data.offer_2_photo}></OfferButton>
            </div> 
            <div className="col-12 col-md-6 mx-auto mt-0 mt-md-5 mt-lg-0 col-lg-4 mb-5 offer-button-container">
              <OfferButton color="secondary" title={`${props.data.offer_3_title_line_1} ${props.data.offer_3_title_line_2}`} image={props.data.offer_3_photo}></OfferButton>
            </div> 
          </div>
        </Container>
      </Container>
      <div className='container-fluid bg-quaternary offer-details pb-5' id={`${offer1Id}`}>
        <Container>
          <div className="row py-5">
            <div className="col-12 mt-5">
              <div className="offer-header row">
                <div className="col-12 col-lg-auto position-relative">
                  <h2 className='d-inline-block me-4 text-primary fs-1 pt-1 mt-lg-2 with-line'>
                    {props.data.offer_1_title_line_1}
                    {(props.data.offer_1_title_line_2 && (<span>
                        <br className="d-none d-md-inline-block"/>
                        <span className="d-inline-block d-md-none">&nbsp;</span>
                      </span>))}
                    {props.data.offer_1_title_line_2}
                  </h2>
                  <img alt="" className="offer-arrow d-block d-lg-none" src="/images/ni/down-main.svg" />    
                </div>
                <div className="col-12 col-lg-auto pe-5 pe-lg-0">
                  <span className="offer-locations my-2 mt-lg-3 row align-items-center">
                    <Button type="button" size="lg" className="smaller order-lg-3 col-auto float-left float-lg-none btn mb-2 ms-1 bg-quaternary cursor-normal text-secondary square-border-se me-2">
                     {props.data.offer_1_tag_1}
                    </Button>
                    <span className='col-auto order-lg-2 text-primary mx-2'>&</span>
                    <Button type="button" size="lg" className="smaller col-auto order-lg-1 btn mb-2 bg-quaternary cursor-normal text-secondary square-border-ne me-2">
                     {props.data.offer_1_tag_2}
                    </Button>
                  </span> 
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-7 pt-5">
              <div>
                {props.data.offer_1_list_1_above && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_1_list_1_above.split(`
`).join(`\n`)}</p>)}
                <ul className={`${props.data.offer_1_list_1_style !== 'tilde' ? 'question-mark' : 'tilde'} ps-5 text-primary`}>
                  {lines(props.data.offer_1_list_1).map(element => (
                    element && (<li>
                      <span className="text-dark">{element}</span>
                    </li>)
                  ))}
                </ul>
                {props.data.offer_1_list_1_below && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_1_list_1_below.split(`
`).join(`\n`)}</p>)}
                <div className="list-header mt-3 mb-4 text-primary fs-3">
                  {props.data.offer_1_head}
                </div> 
                {props.data.offer_1_list_2_above && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_1_list_2_above.split(`
`).join(`\n`)}</p>)}
                <ul className={`${props.data.offer_1_list_2_style !== 'tilde' ? 'question-mark' : 'tilde'} ps-5 text-primary`}>
                  {lines(props.data.offer_1_list_2).map(element => (
                    <li>
                      <span className="text-dark">{element}</span>
                    </li>
                  ))}
                </ul>
                {props.data.offer_1_list_2_below && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_1_list_2_below.split(`
`).join(`\n`)}</p>)}
              </div>
            </div> 
            <div className="col-12 col-lg-5 text-center">
              <img alt={`${props.data.offer_1_title_line_1} ${props.data.offer_1_title_line_2}`} src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-audyt.jpg" className="offer-main-graphic d-none d-lg-block" /> 
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-12 col-lg-4 text-end text-lg-start">
              <span>
                <small className="non-serif fw-bolder">Cena:</small>
                <HashLink to="/kontakt" type="button" size="lg">
                  <Button type="button" size="lg" className="ms-4 mb-2 btn btn-primary text-secondary square-border-ne me-2">
                    {props.data.offer_1_price} {isNumber(props.data.offer_1_price) && (<small>PLN</small>)}
                  </Button>
                </HashLink>
              </span>
            </div> 
            <div className="col-12 col-lg-8 text-end text-lg-start line-decoration-right">
              <img alt={`${props.data.offer_1_title_line_1} ${props.data.offer_1_title_line_2}`} src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-audyt.jpg" className="offer-main-graphic small d-block d-lg-none" /> 
              <HashLink to="/kontakt">
                <Button type="button" size="lg" className="ms-4 mb-2 btn btn-primary text-secondary px-5 square-border-sw fs-3 me-2">
                  {props.data.offer_1_button}
                </Button>
              </HashLink>
            </div> 
            <div className="col-12 text-end text-lg-start">
              <p className="text-dark"><small>
                {props.data.offer_1_details}
              </small></p>
            </div>
          </div>
        </Container>
      </div>
      <div className='container-fluid text-end offer-details pb-5' id={`${offer2Id}`}>
        <Container>
          <div className="row py-5">
            <div className="col-12 pt-5">
              <div className="row">
                <div className="offer-header order-2 order-lg-1 col-12 col-lg-auto ms-auto">
                  <span className="offer-locations my-2 mt-lg-3 row align-items-center pe-5 pe-lg-0">
                    <Button type="button" size="lg" className="smaller order-lg-3 col-auto btn mb-2 ms-1 bg-quaternary cursor-normal text-secondary square-border-se me-2">
                      {props.data.offer_2_tag_1}
                    </Button>
                    <span className='text-primary mx-2 col-auto order-lg-2'>&</span>
                    <Button type="button" size="lg" className="smaller col-auto order-lg-1 btn mb-2 bg-quaternary cursor-normal text-secondary square-border-ne me-2">
                      {props.data.offer_2_tag_2}
                    </Button>
                  </span> 
                </div>
                <div className="position-relative text-start text-lg-end pt-0 ms-auto ms-lg-0 order-1 order-lg-2 col-12 col-lg-auto pt-lg-0">
                  <h2 className='d-inline-block ms-0 ms-lg-4 text-primary fs-1 pt-1 mt-lg-2 with-line'>
                    {props.data.offer_2_title_line_1}
                    {(props.data.offer_2_title_line_2 && (<span>
                        <br className="d-none d-md-inline-block"/>
                        <span className="d-inline-block d-md-none">&nbsp;</span>
                      </span>))}
                    {props.data.offer_2_title_line_2}
                  </h2>
                  <img alt="" className="offer-arrow d-block d-lg-none" src="/images/ni/down-main.svg" />    
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 text-center">
              <img alt={`${props.data.offer_2_title_line_1} ${props.data.offer_2_title_line_2}`} src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-pelne-szkolenie.jpg" className="offer-main-graphic d-none d-lg-block" /> 
            </div>
            <div className="col-12 col-lg-7 pt-5 text-start">
              <div>
                {props.data.offer_2_list_1_above && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_2_list_1_above.split(`
`).join(`\n`)}</p>)}
                <ul className={`${props.data.offer_2_list_1_style !== 'tilde' ? 'question-mark' : 'tilde'} ps-5 text-primary`}>
                  {lines(props.data.offer_2_list_1).map(element => (
                    element && (<li>
                      <span className="text-dark">{element}</span>
                    </li>)
                  ))}
                </ul>
                {props.data.offer_2_list_1_below && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_2_list_1_below.split(`
`).join(`\n`)}</p>)}
                <div className="list-header mt-3 mb-4 text-primary fs-3">
                  {props.data.offer_2_head}
                </div> 
                {props.data.offer_2_list_2_above && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_2_list_2_above.split(`
`).join(`\n`)}</p>)}
                <ul className={`${props.data.offer_2_list_2_style !== 'tilde' ? 'question-mark' : 'tilde'} ps-5 text-primary`}>
                  {lines(props.data.offer_2_list_2).map(element => (
                    <li>
                      <span className="text-dark">{element}</span>
                    </li>
                  ))}
                </ul>
                {props.data.offer_2_list_2_below && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_2_list_2_below.split(`
`).join(`\n`)}</p>)}
              </div>
            </div> 
          </div>
          <div className="row pb-5">
            <div className="col-12 col-lg-4">
              <span>
                <small className="non-serif fw-bolder">Cena:</small>
                <HashLink to="/kontakt">
                  <Button type="button" size="lg" className="ms-4 mb-2 btn btn-primary text-secondary square-border-ne me-2">
                  {props.data.offer_2_price} {isNumber(props.data.offer_2_price) && (<small>PLN</small>)}
                  </Button>
                </HashLink>
              </span>
            </div> 
            <div className="col-12 col-lg-8 line-decoration-left text-start order-lg-first text-end text-lg-end">
             <img alt={`${props.data.offer_2_title_line_1} ${props.data.offer_2_title_line_2}`} src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-pelne-szkolenie.jpg" className="offer-main-graphic small d-block d-lg-none" /> 
              <HashLink to="/kontakt">
                <Button type="button" size="lg" className="btn mb-2 btn-primary text-lg-nowrap text-secondary px-5 square-border-se fs-3 me-2">
                  {props.data.offer_2_button}
                </Button>
              </HashLink>
            </div>
            <div className="col-12 text-end">
              <p className="text-dark"><small>
                {props.data.offer_2_details}
              </small></p>
            </div> 
          </div>
        </Container>
      </div>
      <div className='container-fluid bg-quaternary offer-details pb-5 pt-5' id={`${offer3Id}`}>
        <Container>
          <div className="row py-5">
            <div className="col-12">
              <div className="offer-header row">
                <div className="col-12 col-lg-auto position-relative">
                  <h2 className='d-inline-block me-4 text-primary fs-1 pt-1 mt-lg-2 with-line'>
                    {props.data.offer_3_title_line_1}
                    {(props.data.offer_3_title_line_2 && (<span>
                        <br className="d-none d-md-inline-block"/>
                        <span className="d-inline-block d-md-none">&nbsp;</span>
                      </span>))}
                    {props.data.offer_3_title_line_2}</h2>
                  <img alt="" className="offer-arrow d-block d-lg-none" src="/images/ni/down-main.svg" />    
                </div>
                <div className="col-12 col-lg-auto pe-5 pe-lg-0">
                  <span className="offer-locations my-2 mt-lg-3 row align-items-center">
                    <Button type="button" size="lg" className="smaller col-auto order-lg-3 btn mb-2 ms-1 bg-quaternary cursor-normal text-secondary square-border-se me-2">
                      {props.data.offer_3_tag_1}
                    </Button>
                    <span className='col-auto order-lg-2 text-primary mx-2'>&</span>
                    <Button type="button" size="lg" className="smaller col-auto order-lg-1 btn mb-2 bg-quaternary cursor-normal text-secondary square-border-ne me-2">
                      {props.data.offer_3_tag_2}
                    </Button>
                  </span> 
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-7 pt-5">
              <div>
                {props.data.offer_3_list_1_above && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_3_list_1_above.split(`
`).join(`\n`)}</p>)}
                <ul className={`${props.data.offer_3_list_1_style !== 'tilde' ? 'question-mark' : 'tilde'} ps-5 text-primary`}>
                  {lines(props.data.offer_3_list_1).map(element => (
                    element && (<li>
                      <span className="text-dark">{element}</span>
                    </li>)
                  ))}
                </ul>
                {props.data.offer_3_list_1_below && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_3_list_1_below.split(`
`).join(`\n`)}</p>)}
                <div className="list-header mt-3 mb-4 text-primary fs-3">
                  {props.data.offer_2_head}
                </div> 
                {props.data.offer_3_list_2_above && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_3_list_2_above.split(`
`).join(`\n`)}</p>)}
                <ul className={`${props.data.offer_3_list_2_style !== 'tilde' ? 'question-mark' : 'tilde'} ps-5 text-primary`}>
                  {lines(props.data.offer_3_list_2).map(element => (
                    <li>
                      <span className="text-dark">{element}</span>
                    </li>
                  ))}
                </ul>
                {props.data.offer_3_list_2_below && (<p style={{whiteSpace: 'pre-line'}} className="text-dark">{props.data.offer_3_list_2_below.split(`
`).join(`\n`)}</p>)}
              </div>
            </div> 
            <div className="col-12 col-lg-5 text-center">
              <img alt={`${props.data.offer_3_title_line_1} ${props.data.offer_3_title_line_2}`} src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-tworzenie-kontentu.jpg" className="offer-main-graphic d-none d-lg-block" /> 
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-12 col-lg-4 text-end text-lg-start">
              <span>
                <small className="non-serif fw-bolder">Cena:</small>
                <HashLink to="/kontakt">
                  <Button type="button" size="lg" className="ms-4 mb-2 btn btn-primary text-secondary square-border-ne me-2">
                    {props.data.offer_3_price} {isNumber(props.data.offer_3_price) && (<small>PLN</small>)}
                  </Button>
                </HashLink>
              </span>
            </div> 
            <div className="col-12 col-lg-8 text-center line-decoration-right text-lg-start">
              <img alt={`${props.data.offer_3_title_line_1} ${props.data.offer_3_title_line_2}`} src="/images/media-twins-agencja-kreatywna-szkolenia-z-instagrama-wdrożenie-profilu-na-instagramie-audyt-profilu-na-instagramie-jak-zacząć-na-instagramie-audyt-insta-tworzenie-kontentu.jpg" className="offer-main-graphic small d-block d-lg-none" /> 
              <HashLink to="/kontakt">
                <Button type="button" size="lg" className="ms-4 mb-2 btn btn-primary text-lg-nowrap text-secondary px-5 square-border-sw fs-3 me-2">
                  {props.data.offer_3_button}
                </Button>
              </HashLink>
            </div> 
            <div className="col-12 text-end text-lg-start">
              <p className="text-dark" style={{whiteSpace: 'pre-line'}}><small>
                {props.data.offer_3_details.split(`
`).join(`\n`)}
              </small></p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Offer