import { Container } from "react-bootstrap";
import InstagramButtons from "./InstagramButtons";

const Footer = ({data}) => {
  // const year = new Date().getFullYear()
  return (
    <Container fluid className="bg-primary">
      <Container>
        <footer>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-3 text-secondary mb-lg-0 mb-5">
                <img height="120px" alt="MediaTwins" src="/images/ni/logo-footer.svg" />  
            </div>
            <div className="col-12 col-md-6 col-lg-4 text-secondary contact-options fs-4 flex-column justify-content-end d-flex">
              <div className="text-nowrap mb-3">
                <i className="icon-phone-outline me-2"></i> <a className="text-secondary text-decoration-none" href={`tel:+48${data.tel.replace(/ /g, '')}`}>{data.tel}</a>
              </div>
              <div className="text-nowrap">
                <i className="icon-send me-2"></i> <a className="text-secondary text-decoration-none" href={`mailto:${data.email}`}>{data.email}</a>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-5 mt-5 mt-md-0 text-end instagram-buttons-container justify-content-start justify-content-md-end">
              <InstagramButtons data={data} withoutShadow color={'secondary'}></InstagramButtons>
            </div>
            {/* <div className="col-12 fs-6 text-center mx-auto mt-3 text-secondary non-serif">
              &copy; {year}
            </div> */}
          </div>
        </footer>
      </Container>
    </Container>
  )
}

export default Footer
