import { Helmet } from 'react-helmet'

const Meta = ({ title, description = 'MediaTwins', og = {
  title: 'MediaTwins',
  url: 'https://mediatwins.pl',
  description: 'MediaTwins',
  image: 'https://mediatwins.pl/images/logo.svg',
} }) => {
  return (
    <Helmet>
      <title>{title ? `MediaTwins | ${title}` : 'MediaTwins'}</title>
      {description && (<meta name="description" content={description} />)}
      {og.title && (<meta name="og:title" content={og.title} />)}
      {og.url && (<meta name="og:url" content={og.url} />)}
      {og.description && (<meta name="og:description" content={og.description} />)}
      {og.image && (<meta name="og:image" content={og.image} />)}
    </Helmet>
  )
}

export default Meta
